import React, { Component } from 'react'

export default class Team extends Component {
    render() {
        return (
            <React.Fragment>

                <div className="container">
                    <div className="row">
                        <div className="col-md">
                            <div className="team-member">
                                <img alt='D. Tan' className="rounded-circle mx-auto" src={process.env.PUBLIC_URL + '/img/team/D_Tan.png'} />
                                <h4 style={{ fontSize: "25px" }}>D. Tan</h4>
                                <p className="text-muted">Founder</p>
                                <p className="text-muted">With over 25 years of kitchen and restauratuer experience. Tan is a veteran when it comes to dishing out the most innovative and delicious dishes.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
