import React, { Component } from 'react'
import Navibar from '../ViewComponents/Navibar';
import Footer from '../ViewComponents/Footer';
export default class Order extends Component {
    render() {
        return (
            <React.Fragment>
                <body id="page-top" data-bs-spy="scroll" data-bs-target="#mainNav" data-bs-offset="54" style={{ background: "rgba(255,255,255,0)" }}>
                    <Navibar /> 
                    
                    <Footer></Footer>
                    <script src="assets/bootstrap/js/bootstrap.min.js"></script>
                    <script src="assets/js/bs-init.js"></script>
                    <script src="assets/js/agency.js"></script>
                </body>
            </React.Fragment >
        )
    }
}
