import React, { Component } from 'react'
import '../css/Contact-Form-Clean.css'
import '../css//Newsletter-Subscription-Form.css'
import '../css/Highlight-Blue.css'
import '../css/Social-Icons.css'
import { MusicNote  } from '@material-ui/icons';

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <p className="text-capitalize border rounded joinUs_font">Join Our Social Media Networks!</p>
                    <div className="row d-inline justify-content-center align-items-center" style={{ width: "300px" }}>
                        <div className="col">
                            <div className="social-icons" style={{ background: "#EDEFF0" }}>
                                <a href="https://instagram.com/artease.sg?r=nametag"><i className="fa fa-instagram"></i></a>
                                <a href="https://linktr.ee/artease.sg"><i className="fa fa-tree"></i></a>
                                <a href="https://www.tiktok.com/@artease.sg"><i><MusicNote style={{fontSize:"32px", marginBottom: "4px"}}></MusicNote></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="text-uppercase heading_font" style={{ color: "white", paddingBottom: "50px", paddingTop: "50px" }}>About Artease</h2>
                        <h2 className="text-uppercase subheading_font" style={{ color: "white" }}>Mission</h2>
                        <p className="section-subheading" style={{ color: "white", fontFamily: "Lato" }}>Artease promises a uniquely Singaporean cafe and bubble tea experience. Serving quick and tasty - yet value for money meals, desserts and beverages.</p>
                        <h2 className="text-uppercase subheading_font" style={{ color: "white" }}>Vision</h2>
                        <p className="section-subheading" style={{ color: "white", fontFamily: "Lato", paddingBottom: "50px" }}>Accessible and quality food anywhere, anytime.</p>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: "-37px" }}>
                    <div className="col-lg-12 aboutUs_timeline">
                        <h2 className="text-capitalize aboutUs_font">our legacy</h2>
                        <ul className="list-group timeline">
                            <li className="list-group-item">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/ArteaseSG_Logo_Black_Round.png'})`, backgroundPosition: "center", backgroundSize: "contain" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>May 2011</h4>
                                        <h4 className="subheading subheading_font">Our Humble Beginnings</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Our first humble outlet opened at Tanjong Pagar Plaza. It was the start of an exciting journey.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item timeline-inverted">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/Artease_Graffiti_3.png'})`, backgroundPosition: "center", backgroundSize: "cover" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>July 2011</h4>
                                        <h4 className="subheading subheading_font">More than just a tea</h4>
                                    </div>
                                    <div className="timeline-body"> 
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Our concept has always been more than just tea. We wanted to bring fills and happiness to our customers as well.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/creampasta_roastedchic.png'})`, backgroundPosition: "center", backgroundSize: "cover" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>Feburary 2014</h4>
                                        <h4 className="subheading subheading_font">Research and Develop</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Dishing out food is not easy. The Food and Beverage industry landscape isn't helping either. Unfortunately, we had to close our outlets and part our ways with our lovely customers. However, this setback did not stop us from further improving ourselves.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item timeline-inverted">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/wrap_2.jpg'})`, backgroundPosition: "center", backgroundSize: "cover" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>December 2021</h4>
                                        <h4 className="subheading subheading_font">The 3 Rs</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>We refresh, restart, rebrand our products to taste better and best of all, more affordable. We are more than ready to bring value and smiles to our customers again. This image is our signature chicken tortilla wrap which is loved by many.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/Artease_Graffiti_2.png'})`, backgroundPosition: "center", backgroundSize: "cover" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>April 2023</h4>
                                        <h4 className="subheading subheading_font">Expansion and Franchising</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>We have achieved steady and sustainable growth for our brand, enabling us to expand our outreach to a broader customer base. </p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item timeline-inverted">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/B4G.jpg'})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>2024</h4>
                                        <h4 className="subheading subheading_font">We are officially part of Singapore's Centre for Social Enterprise, raiSE</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>We aim to give back and help the community especially for those who require more help than others.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="timeline-image" style={{ background: `url(${process.env.PUBLIC_URL + '/img/Artease_Crop_Graffiti_1.png'})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4 className='subheading_font'>2024 and Beyond</h4>
                                        <h4 className="subheading subheading_font">Pursuit of Excellence</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>We strive to value add to our customers' dining experience wherever we go.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
