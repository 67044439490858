import React, { Component } from 'react'
import '../css/Features-Clean.css'
import '../css/Social-Icons.css'
import '../css/components.css'
import { Container } from 'react-bootstrap';

export default class Products extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <section className="features-clean" style={{ paddingTop: "1rem", background: "rgba(255,255,255,0.73)" }}>
                            <h2 className="text-uppercase section-heading heading_font" style={{paddingBottom:"1rem"}}>Signature Products</h2>
                            <Container>
                                <div className="row text-center">
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x" style={{paddingBottom:"16rem", paddingRight:"15rem"}}>
                                            <img alt={"cream_pasta"} src={process.env.PUBLIC_URL + '/img/creampasta_roastedchic.png'} style={{ height: "256px" }} />
                                        </span>
                                        
                                        <h4 className="section-heading subheading_font">Cream Pasta with Roasted Chicken</h4>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Creamy, rich, and paired with tender, flavorful roasted chicken. A delightful treat!</p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x" style={{paddingBottom:"16rem", paddingRight:"10rem"}}>
                                            <img alt={"beverage_2"} src={process.env.PUBLIC_URL + '/img/milk_tea.jpeg'} style={{ height: "256px" }} />
                                        </span>
                                        <h4 className="section-heading subheading_font">Earl Grey Milk Tea</h4>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Aromatic tea drink with a hint of bergamot and splash of milk. This is the go-to drink for many seeking a quick quench. Customise it with toppings if you are feeling adventurous!</p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x"style={{paddingBottom:"16rem", paddingRight:"21rem"}}>
                                            <img alt={"Sandwich"} src={process.env.PUBLIC_URL + '/img/focacia.jpg'} style={{paddingTop:"56px", height: "256px" }} />
                                        </span>
                                        <h4 className="section-heading subheading_font">K-BBQ chicken Focaccia Sandwich</h4>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}> Succulent, Korean barbecue-marinated chicken, grilled to perfection and nestled in fluffy, garlic infused focaccia bread. This sandwich is a filling and convenient all-in-one meal.</p>
                                    </div>
                                </div>
                            </Container>
                            <Container>
                                <a href='https://drive.google.com/drive/folders/1YVMpvaMyAmOsc-kw5hNxHusRl162hM5y?usp=share_link' style={{color: "black"}}>
                                    <h2 className="text-capitalize section-heading">View Artease Menu Here</h2>
                                </a>
                            </Container>
                        </section>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
