import React, { Component } from 'react'
import '../css/Features-Clean.css'
import '../css/Social-Icons.css'
import '../css/components.css'
import { Container } from 'react-bootstrap';

export default class ProductsAI extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <section className="features-clean" style={{ paddingTop: "1rem", background: "rgba(255,255,255,0.73)" }}>
                            <h2 className="text-uppercase section-heading heading_font" style={{paddingBottom:"1rem"}}>Our Innovative Food and Beverage Selection</h2>
                            <Container>
                                <div className="row text-center">
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x" style={{paddingBottom:"16rem", paddingRight:"20rem"}}>
                                            <img alt={"cream_pasta"} src={process.env.PUBLIC_URL + '/img/tornado.jpg'} style={{ height: "256px" }} />
                                        </span>
                                        <h4 className="section-heading subheading_font">Japanese curry & Teriyaki Chicken rice with Tornado Omelette</h4>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}></p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x" style={{paddingBottom:"16rem", paddingRight:"12rem"}}>
                                            <img alt={"beverage_2"} src={process.env.PUBLIC_URL + '/img/vanilla_latte.jpeg'} style={{ height: "256px" }} />
                                        </span>
                                        <h4 className="section-heading subheading_font">Vanilla Latte</h4>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Cafe latte with Vanilla flavour</p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x"style={{paddingBottom:"16rem", paddingRight:"11rem"}}>
                                            <img alt={"Jasmine Tea"} src={process.env.PUBLIC_URL + '/img/jasmine.jpeg'} style={{ height: "256px" }} />
                                        </span>
                                        <h4 className="section-heading subheading_font">Jasmine Green Tea</h4>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}>Refreshing and fragrant tea</p>
                                        <p className="text-muted" style={{ fontFamily: "Lato" }}></p>
                                    </div>
                                </div>
                            </Container>
                        </section>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
