import React, { Component } from 'react'
import '../css/components.css'

export default class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer  style={{ background: "rgba(193, 198, 202, 0.79)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4"><span className="copyright" style={{ color: "black", fontFamily: "Lato" }}>Copyright&nbsp;© Artease 2022</span></div>
                            <div className="col-md-4">
                                <ul className="list-inline social-buttons">
                                    <li className="list-inline-item"></li>
                                    <li className="list-inline-item"><a href="https://instagram.com/artease.sg?r=nametag"><i className="fa fa-instagram"></i></a></li>
                                    <li className="list-inline-item"><a href="https://linktr.ee/artease.sg"><i className="fa fa-tree"></i></a></li>
                                    <li className="list-inline-item">
                                        <a href="https://www.tiktok.com/@artease.sg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-music-note">
                                            <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2z"></path>
                                            <path fillRule="evenodd" d="M9 3v10H8V3h1z"></path>
                                            <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5V2.82z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                                <span className="copyright" style={{ color: "black", fontFamily: "Lato" }}>A website by <a style={{color:"black"}} href='https://upnextsg.com'>Upnextsg.com</a></span>
                            </div>
                            <div className="col-md-4">
                                <ul className="list-inline quicklinks">
                                    <li className="list-inline-item">
                                        <a href="/privacy" style={{ fontFamily: "Lato", color: "black" }}>
                                            <p> Privacy Policy</p>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="/terms" style={{ fontFamily: "Lato", color: "black" }}>
                                            <p> Terms of use</p>
                                        </a>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
