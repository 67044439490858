import React, { Component } from 'react'
import '../css/Features-Clean.css'
import '../css/Social-Icons.css'
import '../css/components.css'


export default class Location extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container semitrans_bg" style={{paddingTop: "2rem", paddingBottom: "2rem"}}>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="text-uppercase section-heading heading_font" style={{ color: "rgb(0,0,0)", paddingBottom: "2rem"}}>Locate us</h2>
                        </div>
                    </div>
                    <div className="row">
                        {/* <Card style={{ width: '18rem' }}>
                            <Card.Img  src={process.env.PUBLIC_URL + '/img/IMG_8648.png'} />
                            <Card.Body>
                                <Card.Title>Queenstown</Card.Title>
                                <Card.Text>
                                    Right at the fringe of Singapore's CBD area. 53 Margret Road
                                </Card.Text>
                                <a href="https://goo.gl/maps/b7PrsF42s1VZEWbFA" style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here for directions </i></a>
                            </Card.Body>
                        </Card> */}
                        {/* <div className="col-sm-6 col-md-4 portfolio-item">
                        <div style={{padding: "0.5rem"}}></div>
                            <img alt={"place_1"} className="img-fluid store-img" src={process.env.PUBLIC_URL + '/img/queenstown_store.png'}   />
                            <div className="portfolio-caption">
                                <h4 style={{ color: "var(--bs-dark)", fontFamily: "Lato", fontWight: "bold", fontSize: "25px" }}>Queenstown</h4>
                                <p className="text-muted" style={{ fontFamily: "Lato" }}>53 Margaret Drive, #01-00, S(149297)</p>
                                <a href="https://goo.gl/maps/ciSg1X7a8Khmhg5y8" style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here for directions </i>
                                    <br/>
                                </a>
                                <a href='https://g.page/r/CRAAdDyvZW5gEB0/review'style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here to review </i>
                                </a>
                            </div>
                        </div> */}
                        <div className="col-sm-6 col-md-4 portfolio-item">
                            <div style={{padding: "0.5rem"}}></div>
                            <img alt={"place_2"} className="img-fluid store-img" src={process.env.PUBLIC_URL + '/img/RP.jpeg'} />
                            <div className="portfolio-caption" style={{ color: "var(--bs-dark)" }}>
                                <h4 style={{ color: "var(--bs-dark)", fontFamily: "Lato", fontWight: "bold", fontSize: "25px" }}>TRCC Building, Republic Polytechnic, Singapore</h4>
                                <p className="text-muted" style={{ fontFamily: "Lato" }}>5 Woodlands Avenue 9, #01-01, Republic Polytechnic, S(738962)</p>
                                <a href="https://maps.app.goo.gl/ULQAmdYPcfkeaaEH9" style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here for directions </i>
                                    <br/>
                                </a>
                                <a href='https://g.page/r/CaGHCfbom50tEBM/review'style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here to review </i>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 portfolio-item">
                            <div style={{padding: "0.5rem"}}></div>
                            <img alt={"place_2"} className="img-fluid store-img" src={process.env.PUBLIC_URL + '/img/woodlands_store.JPG'} />
                            <div className="portfolio-caption" style={{ color: "var(--bs-dark)" }}>
                                <h4 style={{ color: "var(--bs-dark)", fontFamily: "Lato", fontWight: "bold", fontSize: "25px" }}>Woodlands Civic Centre</h4>
                                <p className="text-muted" style={{ fontFamily: "Lato" }}>900 S Woodlands Dr, #01-03, S(730900)</p>
                                <a href="https://goo.gl/maps/BKD8rkVpiDUbDXWB6" style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here for directions </i>
                                    <br/>
                                </a>
                                <a href='https://g.page/r/Ce6mtRn8va7lEB0/review'style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here to review </i>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 portfolio-item">
                            <div style={{padding: "0.5rem"}}></div>
                            <img alt={"place_2"} className="img-fluid store-img" src={process.env.PUBLIC_URL + '/img/Punggol.PNG'} />
                            <div className="portfolio-caption" style={{ color: "var(--bs-dark)" }}>
                                <h4 style={{ color: "var(--bs-dark)", fontFamily: "Lato", fontWight: "bold", fontSize: "25px" }}>One Punggol</h4>
                                <p className="text-muted" style={{ fontFamily: "Lato" }}>1 Punggol Drive One, #02-45, S(828629)</p>
                                <a href="https://goo.gl/maps/wSJgWm5fruWX7cN46" style={{ color: "#000000" }}>
                                    <i className="fa fa-map"> Click here for directions </i>
                                    <br/>
                                    <i className="fa fa-map"> Click here to review </i>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="container semitrans_bg" style={{paddingTop: "2rem", paddingBottom: "2rem"}}>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="text-uppercase section-heading heading_font" style={{ color: "rgb(0,0,0)", paddingBottom: "2rem"}}>Like Us?</h2>
                            <h2 className="text-uppercase section-heading heading_font" style={{ color: "rgb(0,0,0)", paddingBottom: "2rem"}}>Leave Us A Review at Google!</h2>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
