import React, { Component } from 'react'
import '../css/Features-Clean.css'
import '../css/Social-Icons.css'
import '../css/components.css'
import { Container } from 'react-bootstrap';
import Contact from './Contact';
import Navibar from '../ViewComponents/Navibar';
import Team from './Team';
import Footer from '../ViewComponents/Footer';
import Products from './Products';
import ProductsAI from './ProductAI';
import Location from './Location';
import About from './About';

export default class Main extends Component {
    render() {
        return (
            <React.Fragment>
                <body id="page-top" data-bs-spy="scroll" data-bs-target="#mainNav" data-bs-offset="54" style={{ background: "rgba(255,255,255,0)" }}>
                    <Navibar />
                    <img alt={"beverage_1"} src={process.env.PUBLIC_URL + 'img/top_bg_Website.jpg'}  style={{ width: "100%", marginTop:"5rem" , marginBottom:"5rem"}} />
                    <div className="masthead" style={{ backgroundColor: "rgba(193, 198, 202, 0.79)"}}>
                        <div className="container" style={{ background: "#00000000"}}>
                            <section id="products" style={{ marginTop:"-150px", paddingBottom: "5rem" }}>
                                <Container>
                                    <Products></Products>
                                </Container>
                            </section>
                            <section id="products" style={{ marginTop:"-150px", paddingBottom: "0px" }}>
                                <Container>
                                    <ProductsAI></ProductsAI>
                                </Container>
                            </section>
                            <section id="location" className='location_style'>
                                <Location></Location>
                            </section>
                        </div>
                    </div>
                    <section id="about" style={{ paddingTop: "68px", background: "#15292F" }}>
                    <Container>
                        <About></About>
                    </Container>
                    </section>
                    <section className="bg-light" id="team" style={{ paddingBottom: "50px" }}>
                        <Container>
                            <Team></Team>
                        </Container>
                    </section>
                    <section id="contact" style={{ background: `url(${process.env.PUBLIC_URL + '/img/artease_mural_1.JPG'})` }}>
                        <Container>
                            <Contact></Contact>
                        </Container>
                    </section>
                    <Footer></Footer>
                    <script src="assets/bootstrap/js/bootstrap.min.js"></script>
                    <script src="assets/js/bs-init.js"></script>
                    <script src="assets/js/agency.js"></script>
                </body>
            </React.Fragment >
        )
    }
}
