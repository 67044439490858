import React, { Component } from 'react'

import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './View/Main';
import Privacy from './View/Privacy';
import Terms from './View/Terms';
import Order from './View/Order';
export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/order" element={<Order />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }

}


