import React, { Component } from 'react'
import '../css/Contact-Form-Clean.css'
import '../css//Newsletter-Subscription-Form.css'
import '../css/Highlight-Blue.css'

import emailjs from '@emailjs/browser';
import ReactJsAlert from "reactjs-alert";


import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            showErrorAlert: false,
            to_name: '',
            message: '',
            reply_to: '', 
        };

        // This binding is necessary to make `this` work in the callback
        this.handleSubmit = this.handleSubmitMail.bind(this);
        this.handleSubmit = this.handleSubmitMail2.bind(this);
        this.handleSubmit = this.handleSubSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }



    handleSubmitMail = (event) => {
        event.preventDefault();
        let id =  process.env.REACT_APP_EMAILJS_ID
        
        try {
            emailjs.send("service_y6d33qr", "template_prf28rj", {
                to_name: this.state.name,
                message: this.state.message,
                reply_to: this.state.reply_to,
            }, id)
                .then((result) => {
                    this.setState({ showAlert: true })
                }, (error) => {
                    this.setState({ showErrorAlert: true })
                });
        } catch (error) {
            this.setState({ showErrorAlert: true })
        }
    }

    handleSubmitMail2(event) {
        console.log('sent')
        this.setState({ showAlert: true })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleSubSubmit(event) {
        event.preventDefault();
        try {
            console.log('okie')
        } catch (error) {
            console.log('error2')
        }
    }
    
    render() {
        return (
            <div className='main_bg'>
                {/* <WhatsAppWidget
                    phoneNo="6588530683"
                    position="right"
                    widgetWidth="300px"
                    widgetWidthMobile="200px"
                    autoOpen={true}
                    autoOpenTimer={5000}
                    messageBox={true}
                    messageBoxTxt="Hi, I want to buy 8 pints of gelato!"
                    iconSize="40"
                    iconColor="black"
                    iconBgColor="#fbb423"
                    headerIcon={process.env.PUBLIC_URL + 'img/ArteaseSG_Logo_White_Round.png'}
                    headerIconColor="pink"
                    headerTxtColor="black"
                    headerBgColor="#fbb423"
                    headerTitle="Artease"
                    headerCaption="Online"
                    bodyBgColor="#bbb"
                    chatPersonName="Artease"
                    chatMessage={<>Hi there 👋 <br /><br /> What would you like to eat today? I recommend you checking out our menu!</>}
                    footerBgColor="#999"
                    btnBgColor="#fbb423"
                    btnTxtColor="black"
                    btnTxt="Start Chat"
                /> */}
                {this.state.showAlert ? <ReactJsAlert
                    type="info"
                    title={"Thank you"}
                    status={this.state.showAlert}
                    quote={"Form has been submitted successfully"}
                    Close={() => this.setState({ showAlert: false })}
                /> : null}
                {this.state.showErrorAlert ? <ReactJsAlert
                    type="info"
                    title={"Something went wrong, Please use other forms of communciation."}
                    status={this.state.showErrorAlert}
                    quote={"Form has been submitted successfully"}
                    Close={() => this.setState({ showErrorAlert: false })}
                /> : null}

                <section className="semitrans_bg">
                    <div>
                        <img src={this.state.img}></img>
                    </div>
                    <div className="container"> 
                        <div className="col-lg-12 text-center">
                            <h2 className="text-capitalize section-heading heading_font" style={{ color: "rgb(0,0,0)", marginTop: "1rem" }}>Contact us</h2>
                            <p className="text-center subheading_font"> Get in touch with us through any of our social media outlets or email us ask@arteasesg.com <br/>Thank you and we look forward to seeing you at Artease soon.</p>
                        </div>
                    </div>
                    {/* <div className="container">
                        <div className="col-lg-12 text-center">
                            <h2 className="text-capitalize section-heading heading_font" style={{ color: "rgb(0,0,0)", marginTop: "1rem" }}>Self Collection Pre-Ordering</h2>
                            <p className="text-center subheading_font"> WhatsAPP Message or Call us at 88530683 for Pre-Orders(i.e. Gelato) or Self Collect </p>
                        </div>
                    </div> */}
                    <div className="container">
                        <div className="col-lg-12 text-center">
                            <h2 className="text-capitalize section-heading heading_font" style={{ color: "rgb(0,0,0)", marginTop: "1rem" }}>For franchising information</h2>
                            <p className="text-center subheading_font"> <a href="https://drive.google.com/file/d/1rwIVEP0Ky2wGkZyP_xIaHgEb5XjhgczT/view?usp=share_link">Click here for franchise brochure</a> If you are interested email us and along with the application form<a href="https://drive.google.com/file/d/1rwIVEP0Ky2wGkZyP_xIaHgEb5XjhgczT/view?usp=share_link"> Click here to download the form</a></p>
                        </div>
                    </div>
                </section>
                    
                {/* <section className="contact-clean semitrans_bg" >
                    <form onSubmit={this.handleSubmitMail}>
                        <h2 className="text-center">Contact Us</h2>
                        <div className="mb-3">
                            <input className="form-control" type="text" name="to_name" placeholder="Name" required onChange={this.handleChange} /></div>
                        <div className="mb-3">
                            <input className="form-control" type="email" name="reply_to" placeholder="Email" required onChange={this.handleChange} /></div>
                        <div className="mb-3">
                            <textarea className="form-control" name="message" required onChange={this.handleChange} placeholder="Your feedback and message. Thank you." rows="15"></textarea></div>
                        <div className="mb-3">
                            <button className="btn" style={{backgroundColor: "#15292F", color: "white"}} type="submit">send</button></div>
                    </form>
                </section> */}

                {/* <section className="newsletter-subscribe" style={{ background: "rgba(223,232,239,0.54)", marginTop: "100px" }}>
                    <div className="container">
                        <div className="intro">
                            <h2 className="text-center" style={{ color: "var(--bs-white)" }}>Subscribe for more!</h2>
                            <p className="text-center" style={{ fontFamily: "Lato", color: "var(--bs-white)" }}>Get the latest marketing information, promotions, and technology trends from us for free today!</p>
                        </div>
                        <form className="d-flex justify-content-center flex-wrap" onClick={this.handleSubSubmit}>
                            <div className="mb-3">
                                <input className="form-control" type="email" name="email" placeholder="Your Email" />
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-primary btn-xl subscribe_button"> Subscribe </button>
                            </div>
                        </form>
                    </div>
                </section> */}
            </div>
        )
    }
}
