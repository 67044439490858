import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import '../css/components.css'

export default class Navibar extends Component {
    render() {
        return (
            <React.Fragment>
                <nav className="navbar navbar-light navbar-expand-lg fixed-top navibartop" id="mainNav">
                    <Container>
                        <a className="navbar-brand nav_itemstyle" href="#page-top">
                            <img className='nav_logostyle' alt={"Logo"} src={process.env.PUBLIC_URL + '/img/ArteaseSG_Logo_Black_Long.png'} />
                        </a>
                        <button data-bs-toggle="collapse" data-bs-target="#navbarResponsive" className="navbar-toggler navbar-toggler-right" type="button" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ms-auto text-uppercase">
                                <li className="nav-item">
                                    <a className="nav-link nav_itemtyle" href="#products">Products</a>
                                </li>
                                <li className="nav-item nav_itemtyle"></li>
                                <li className="nav-item">
                                    <a className="nav-link nav_itemtyle " href="#location">Location</a>
                                </li>
                                <li className="nav-item nav_itemtyle"></li>
                                <li className="nav-item">
                                    <a className="nav-link nav_itemtyle" href="#about">About</a>
                                </li>
                                <li className="nav-item nav_itemtyle"></li>
                                <li className="nav-item">
                                    <a className="nav-link nav_itemtyle" href="#contact">Contact</a>
                                </li>
                                <li className="nav-item nav_itemtyle"></li>
                            </ul>
                            <ul className="navbar-nav" style={{color: "var(--bs-white)"}}>
                                <li className="nav-item" style={{color: "var(--bs-gray-100)"}}></li>
                            </ul>
                        </div>
                    </Container>
                </nav>
            </React.Fragment>
        )
    }
}
