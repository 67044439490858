import React, { Component } from 'react'
import Footer from '../ViewComponents/Footer'
import Navibar from '../ViewComponents/Navibar'

export default class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <Navibar></Navibar>
        <section>
          <div style={{ fontFamily: "lato", fontSize: "45px" }}>under construction</div>
          <a href='/'> Go Back</a>
        </section>

        <Footer></Footer>
      </React.Fragment>
    )
  }
}
